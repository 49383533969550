<template>
  <header>
    <div class="l-header">
      <div class="l-header__inner">
        <div v-if="!isLoginRelated && !auth" class="l-header__logo">
          <router-link to="/home">
            <img src="/v_resources/assets/img/common/logo01.svg" alt="CBA" />
          </router-link>
        </div>
        <div v-else-if="!isLoginRelated">
          <h1 class="l-header__title">{{ pageTitle }}</h1>
        </div>
        <div class="l-header__right">
          <router-link v-if="auth" to="/profile" class="l-header__right__user">
            <span></span>
            {{ user.name }}
          </router-link>
          <div class="l-header__tooltip">
            <div v-if="auth" class="l-header__right__setting">
              <span></span>
            </div>
            <div class="l-header__tooltip__tip">
              <ul>
                <li class="l-header__tooltip__tip__link" @click="logout">ログアウト</li>
              </ul>
            </div>
          </div>
          <div class="l-header__tooltip">
            <div v-if="auth" class="l-header__right__question">
              <span></span>
            </div>
            <div class="l-header__tooltip__tip right">
              <ul>
                <li class="l-header__tooltip__tip__link" @click="gotoHelpCenter">ヘルプセンターに移動</li>
                <li v-if="!isChatOpen" class="l-header__tooltip__tip__link" @click="openChat">チャットで問い合わせ</li>
                <li v-else class="l-header__tooltip__tip__link" @click="closeChat">チャットを閉じる</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import loginRelated from "@/mixin/loginRelated";
import axios from "axios";
import { API_URL } from "@/const";

export default {
  mixins: [loginRelated],
  props: {
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      isChatOpen: false,
      auth: this.$store.getters["auth/get"],
      user: this.$store.getters["userInfo/get"],
    }
  },
  methods: {
    logout() {
      return axios
        .patch(API_URL.AUTH.LOGOUT)
        .then(() => {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;max-age=0';
          }
          this.$router.push("/login", () => {
            this.$router.go({
              path: this.$router.currentRoute.path,
              force: true,
            });  
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotoHelpCenter() {
      window.open(`https://${process.env.VUE_APP_ZENDESK_SUBDOMAIN}.zendesk.com/hc/ja`, '_blank');
    },
    openChat() {
      this.isChatOpen = true;
      this.$zendesk.show();
      this.$zendesk.open();
    },
    closeChat() {
      this.isChatOpen = false;
      this.$zendesk.hide();
    },
  },
};
</script>
